.home {
    background: #FAFAFA;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 30px;

    .header {
        width: 1200px;
        height: auto;
        background: #fff;
        border-radius: 2px;
        box-sizing: border-box;
        margin-top: 12px;
        padding: 0;
        border: 1px solid #efefef;
    }

    .header-title {
        height: 52px;
        background: #fcfbfc;
        border-radius: 1px 1px 0 0;
        border-bottom: 1px solid #efefef;
        line-height: 52px;
        font-weight: 700;
        color: #333;
        font-size: 14px;
        padding-left: 16px;
    }

    .search-item {
        display: flex;
        padding-top: 16px;
        padding-left: 16px;
        padding-right: 16px;
        align-items: center;
    }

    .item-label {
        font-size: 14px;
        font-weight: 400;
        margin-right: 16px;
        position: relative;
        color: #666;
        display: inline-block;
        height: 32px;
        line-height: 32px;
    }

    .input {
        width: 490px;
        border-radius: 0;
    }

    .item-row {
        display: flex;
    }

    .option {
        border-radius: 2px;
        margin-right: 10px;
        height: 32px;
        margin-bottom: 6px;
    }

    .search-item-1 {
        display: flex;
        padding-top: 16px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .option-box {
        flex: 1;
    }

    .search-item-2 {
        display: flex;
        padding-top: 10px;
        padding-left: 16px;
        padding-right: 16px;
    }

    .search-box {
        width: 100%;
        border-top: 1px solid #efefef;
        margin-top: 10px;
        padding: 16px 0 16px 86px;
    }

    .search-btn {
        border-radius: 2px;
        margin-right: 15px;
        height: 40px;
        width: 100px;
    }

    .bottom-toolbar {
        display: flex;
        height: 52px;
        line-height: 52px;
        border-bottom: 1px solid #efefef;
        width: 1200px;
        margin: 20px auto 0 auto;
        background-color: #fff;
    }

    .bottom-toolbar-left {
        width: 40%;
        padding-left: 16px;
        font-weight: 400;
        color: #333;
        font-size: 14px;
    }

    .toolbar-left-red {
        color: #ff2a00;
        padding: 0 4px;
    }

    .add-crm {
        border-radius: 2px;
        height: 32px;
    }

    .bottom-toolbar-right {
        width: 60%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 16px;
    }

    .company-box {
        display: flex;
        width: 1200px;
        margin: 0 auto;
        background: #fff;
        padding: 24px 0;
        border-bottom: 1px solid #efefef;
        align-items: center;
        padding-left: 16px;
    }

    .company-box:hover {
        background-color: #f5faff;
    }

    .info-list-left {
        // width: 126px;
        width: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .info-list-left img {
        width: 78px;
        height: 78px;
    }

    .info-list-right {
        flex: 1;
        padding-right: 16px;
    }
    .card-title-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .card-title {
        font-size: 18px;
        font-weight: 700;
        color: #333;
        margin-right: 12px;
        line-height: 24px;
        cursor: pointer;
    }
    .tag-box {
        margin-top: 5px;
        margin-bottom: 8px;
    }
    .card-sub-title {
        display: flex;
        align-items: center;
        line-height: 18px;
    }
    .sub-title-grey {
        font-weight: 400;
        color: #999;
    }
    .sub-title-black {
        display: block;
        font-weight: 400;
        color: #333;
        margin-right: 24px;
    }
    .sub-title-blue {
        font-weight: 400;
        color: #0081ff;
        margin-right: 24px;
        cursor: pointer;
    }
}

body {
    background: #FAFAFA !important;
}